<template>
  <div class="resize">
    <label class="resize-row standart-text">{{ $t('attach') }}</label>
    <div class="l-col">
      <base-input :value="name" :readonly="true" @input="onChange"></base-input>
    </div>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import BaseInput from '@/components/BaseInput/BaseInput';
import { OPTariff } from '@/models/OP/OPTariff';
export default {
  name: 'DeattachDisk',
  components: { BaseInput },
  mixins: [setFocus],
  props: {
    instance: {
      type: String,
      required: true,
      // validator: obj => obj instanceof OPTariff,
      // validator: obj => obj instanceof OPTariff,
    },
  },
  // data() {
  // return {
  //   instance: [],
  // };
  // },
  computed: {
    name() {
      return `${this.serverName} (${this.instance})`;
      // return `(${this.instance})`;
    },
    serverName() {
      return this.$store.state.moduleStack.servers.find(x => x.id === this.instance).name;
    },
    // options() {
    //   return this.$store.getters['moduleStack/servers'].map(x => `${x.name} (${x.id})`);
    // },
  },
  methods: {
    // onChange(event) {
    onChange() {
      // this.$emit('instance', event);
      this.$emit('instance', this.instance);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "attach": "Отключиться от инстанса:",
    "sure": {
    "confirm": "Подключить"
    },
    "text": "После сохранения нельзя будет уменьшить размер диска",
    "notice": "Для вступления в силу - перезагрузите сервер"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.resize {
  text-overflow: unset;
  overflow: visible;

  &-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: .75rem .75rem;
&-input {
  padding: 1.5rem 1.5rem;
}
  }
}
</style>
