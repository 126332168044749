<template>
  <div class="stack-context-menu">
    <template v-if="menu.length && menu[0].length">
      <div v-for="(group, i) in menu" :key="i" class="stack-context-menu__group">
        <component
          :is="item.attrs ? 'a' : 'div'"
          v-for="item in group"
          :key="item.key"
          v-bind="item.attrs"
          class="stack-context-menu__item standart-title"
          :class="{ [`stack-context-menu__item--${item.color}`]: !!item.color }"
          :disabled="item.disabled"
          @click="onClick(item)"
        >
          <!--          @click="-->
          <!--          (quota < 1 && item.key === 'resize') || (item.key === 'snapshot' && quota < tariff.size)-->
          <!--          ? ''-->
          <!--          : onClick(item)-->
          <!--          "-->
          <plain-button
            v-if="
              (quota < 1 && item.key === 'resize') ||
              (item.key === 'snapshot' && quota < tariff.size) ||
              (item.key === 'snapshot' && quotaSnap === 0)
            "
            v-tooltip="{
              content: item.key === 'resize' ? textResize : textSnapshot,
              placement: 'auto',
              container: false,
              autoHide: false,
              trigger: 'click hover',
            }"
            icon="error"
            size="medium"
            color="del"
            tabindex="-1"
            class="stack-context-menu__item-btn"
          />
          {{ $t(`actions.${item.key}`) }}
        </component>
      </div>
    </template>
    <template v-else>
      <div class="stack-context-menu__noact standart-text">
        <div v-if="isSuspended" class="standart-title">
          {{ $t('noact.suspended') }}
        </div>
        <div>{{ $t('noact.noactions') }}</div>
      </div>
    </template>
  </div>
</template>

<script>
import showErrorModal from '@/mixins/showErrorModal';
import serialize from '@/utils/serialize';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import ResizeDisk from '@/mixins/resizeDisk';
import AttachDisk from '@/mixins/attachDisk';
import deleteDisk from '@/mixins/deleteDisk';
import DeattachDisk from '@/mixins/deattachDisk';
import { dev } from '@/layouts/LoginPage/mixins/currentProvider';
import renameDisk from '@/mixins/renameDisk';
import changeDiskDescription from '@/mixins/changeDiskDescription';
import Vue from 'vue';
export default {
  name: 'DiskContextMenu',
  mixins: [
    showErrorModal,
    ResizeDisk,
    renameDisk,
    AttachDisk,
    changeDiskDescription,
    DeattachDisk,
    deleteDisk,
  ],
  props: {
    tariff: {
      type: Object,
      required: true,
    },
    typeAction: {
      type: String,
      required: true,
      validator: value => ['disk', 'server'].includes(value),
    },
    root: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      res: '',
      timerId: '',
      timerCnt: 0,
      isProcessing: false,
      snapId: '',
      imageId: '',
    };
  },
  computed: {
    textSnapshot() {
      // Продолжить от сюда!!!
      if ((this.quota < 1 || this.quota < this.tariff.size) && this.quotaSnap > 0) {
        return `Вы достигли квоты по объему сетевых дисков.<br /><br />

Перейдите в раздел <strong><a href="${localStorage.getItem('urlHash')}stack/${
          this.bmId
        }/quotas">Квоты</a></strong> для увеличения. `;
      } else if (this.quotaSnap === 0 && this.quota > this.tariff.size) {
        return `Вы достигли квоты по количеству снимков диска.<br /><br />

Перейдите в раздел <strong><a href="${localStorage.getItem('urlHash')}stack/${
          this.bmId
        }/quotas">Квоты</a></strong> для увеличения. `;
      } else
        return `Вы достигли квоты по количеству снимков диска и объему сетевых дисков.<br /><br />

Перейдите в раздел <strong><a href="${localStorage.getItem('urlHash')}stack/${
          this.bmId
        }/quotas">Квоты</a></strong> для увеличения. `;
    },
    textResize() {
      return `Вы достигли квоты по объему сетевых дисков.<br /><br />

Перейдите в раздел <strong><a href="${localStorage.getItem('urlHash')}stack/${
        this.bmId
      }/quotas">Квоты</a></strong> для увеличения. `;
    },
    isColorResize() {
      return this.quota < 1 ? 'warn' : '';
    },
    isColorSnapshot() {
      return this.quota < this.tariff.size || this.quotaSnap === 0 ? 'warn' : '';
    },
    serverState() {
      return this.tariff.status;
    },
    isAttached() {
      return this.tariff.attachments.length > 0;
    },
    status() {
      return this.tariff.status.code;
    },
    serverId() {
      return this.tariff.attachments.length > 0 ? this.tariff.attachments[0].server_id : '';
    },
    servers() {
      return this.$store.getters['moduleStack/servers'];
    },
    projectId() {
      return this.$store.getters['moduleStack/project_id'];
    },
    date() {
      return this.$d(Date.now(), 'short').replaceAll('.', '_');
    },
    time() {
      return this.$d(Date.now(), 'long').replace(/[^\d]/g, '_');
    },
    quotaSnap() {
      return this.$store.getters['moduleStack/aviableSnapshots'];
    },
    id() {
      return this.tariff.id;
    },
    typeDisk() {
      return this.tariff.volume_type;
    },
    nameDisk() {
      return this.tariff.name;
    },
    snapshotsId() {
      return (
        this.$store.state.moduleStack.snapshots
          .filter(x => x.volume_id === this.id)
          .map(i => i.id) || []
      );
    },
    isBoot() {
      return this.tariff.bootable === 'true';
    },
    isWrongStatus() {
      return ![12, 13, 37].includes(this.tariff.status.code);
    },
    showDeAttach() {
      return ![12, 13, 37].includes(this.tariff.status.code) && this.isBoot;
    },
    processingStatus() {
      return 2;
    },
    isSuspended() {
      return this.tariff.abusesuspend === 'on' || this.tariff.employeesuspend === 'on';
    },
    menu() {
      const status = this.serverState.code;

      const arr = [];
      arr.push([
        {
          key: 'rename',
          handler: 'rename',
        },
        {
          key: 'descr',
          handler: 'descr',
        },
        {
          key: 'resize',
          id: this.id,
          handler: 'resize',
          color: this.isColorResize,
          bootable: this.tariff.bootable,
          action: 'os-extend',
          disabled: ![13, 15].includes(status),
        },
        {
          key: 'snapshot',
          handler: 'snapshot',
          func: 'createSnapshot',
          color: this.isColorSnapshot,
          action: {
            snapshot: {
              name: 'snapshot' + '_' + this.time,
              description: this.tariff.id,
              volume_id: this.id,
              force: true,
              metadata: this.tariff.volume_image_metadata,
            },
          },
          disabled: [4, 27].includes(status),
        },
        {
          key: 'attach',
          handler: 'attach',
          action: {
            volumeAttachment: {
              volumeId: this.id,
            },
          },
          disabled: ![15].includes(status),
        },
        {
          key: 'image',
          handler: 'image',
          func: 'createImage',
          action: {
            'os-volume_upload_image': {
              force: true,
              image_name: 'image_' + this.nameDisk,
            },
          },
          disabled: ![15].includes(status) || this.tariff.size > 128,
        },
        {
          key: 'deattach',
          handler: 'deattach',
          action: {
            'os-detach': {
              attachment_id: this.id,
            },
          },
          disabled:
            ([13, 1].includes(status) && this.isBoot) ||
            status === 15 ||
            status === 4 ||
            status === 27 ||
            this.root,
        },
        {
          key: this.tariff.bootable === 'true' ? 'abootable' : 'bootable',
          handler: 'bootable',
          action: 'bootable',
        },
        {
          key: 'delete',
          color: 'del',
          handler: 'delete',
          action: 'delete',
          disabled: ![15, 4, 31, 32, 40].includes(status),
        },
      ]);
      return arr;
    },
  },
  mounted() {
    if (this.processingStatus === 1) this.updateStatus();
  },
  beforeDestroy() {
    if (this.timerId) clearTimeout(this.timerId);
  },
  methods: {
    price(name) {
      // console.log(this.$store.state.moduleStack.price.find(x => x.intname === name)[dev]);
      return this.$store.state.moduleStack.price.length > 0
        ? this.$store.state.moduleStack.price.find(x => x.intname === name)[dev]
        : null;
    },
    cost(name) {
      return this.price(name) ? this.price(name) * this.tariff.size : 0;
    },
    onClick(item) {
      if (item.disabled) return;
      if (item.handler === 'rename') this.renameDisk(this.tariff);
      if (item.handler === 'descr') this.changeDiskDescription(this.tariff);
      if (item.action && item.handler === 'action') this.snapshot(item);
      if (item.action && item.handler === 'image') this.image(item);
      if (item.action && item.handler === 'attach') this.attachDisk(this.servers, this.id);
      if (item.action && item.handler === 'deattach') this.deattachDisk(this.serverId, this.id);
      if (item.action && item.handler === 'delete') this.deleteDisk(this.tariff);
      if (item.action && item.handler === 'bootable') this.bootableDisk();
      if (item.action && item.handler === 'snapshot') {
        if (this.quotaSnap > 0 && this.quota > this.tariff.size) {
          this.snapshot(item);
        } else if (this.quotaSnap === 0 && this.quota > this.tariff.size) {
          this.showError(
            'Вы достигли квоты по количеству снимков диска. Перейдите в раздел "Квоты" для увеличения.'
          );
        } else if (this.quota < this.tariff.size && this.quotaSnap > 0) {
          this.showError(
            'Вы достигли квоты по объему сетевых дисков. Перейдите в раздел "Квоты" для увеличения.'
          );
        } else
          this.showError(
            'Вы достигли квоты по количеству снимков диска и объему сетевых дисков. Перейдите в раздел "Квоты" для увеличения.'
          );
      }
      if (item.action && item.handler === 'resize') {
        if (this.quota !== 0) this.resizeDisk(this.tariff.size, this.projectId, this.id);
        else
          this.showError(
            'Вы достигли квоты по объему сетевых дисков. Перейдите в раздел "Квоты" для увеличения.'
          );
      }
    },
    snapshot(action) {
      const that = this;
      let self;
      const selfName = 'ServerAction';
      let res = '';
      let errorCode = '';
      const alert = !this.isAttached ? 'modal.sure.text' : 'modal.sure.textAlarm';
      let time;
      // console.log(`${action.key}_${this.tariff.volume_type}`);
      this.$modals.open({
        name: selfName,
        html: true,
        text: this.$t(alert, {
          // text: this.$t('modal.sure.text', {
          msg: this.$t(`text.${action.key}`),
          cost: this.$n(
            this.cost(`${action.key}_${this.tariff.volume_type}`),
            'currency',
            this.$i18n.locale
          ),
        }),
        onOpen(inst) {
          self = inst;
        },
        footer: {
          confirm: {
            props: {
              title: this.$t('modal.sure.confirm'),
            },
            on: {
              click: () => {
                self.text = null;
                self.component = BaseLoader;
                self.closable = false;
                self.footer = false;
                time = Date.now();
                this.isProcessing = true;
                this.createSnapshot(JSON.stringify(action.action))
                  .then(data => {
                    this.snapId = data.id;
                    res = 'success';
                  })
                  .catch(e => {
                    // console.log(e.response.data);
                    // res = 'fail';
                    res = e.response.data.overLimit.message;
                    errorCode = e.response.data.overLimit.code;
                  })
                  .finally(() => {
                    const delay = Date.now() - time < 1000 ? 1000 : 0;
                    setTimeout(() => {
                      self.component = null;
                      // console.log(res);
                      self.closable = true;
                      self.text =
                        res === 'success'
                          ? this.$t(`modal.sure.${res}`)
                          : errorCode === 413
                          ? this.$t('modal.sure.errorSnapshot')
                          : res;
                      // self.text = this.$t(`modal.sure.${res}`);
                      self.footer = {
                        centered: true,
                        confirm: {
                          props: { title: this.$t('modal.sure.close') },
                          on: {
                            click: () => {
                              // Vue.set(this.modal.footer.confirm.props, 'loading', true);
                              this.$modals.close();
                              this.$store.dispatch('moduleStack/fetchSnapshotsServer');
                              if (this.typeAction === 'disk')
                                this.$router
                                  .push({
                                    name: 'DiskSnapshots',
                                    params: {
                                      diskid: this.tariff.id,
                                    },
                                  })
                                  .catch(err => {
                                    if (
                                      err.name !== 'NavigationDuplicated' &&
                                      !err.message.includes(
                                        'Avoided redundant navigation to current location'
                                      )
                                    ) {
                                      this.showErrorModal(err);
                                      // console.log(this.showErrorModal);
                                    }
                                  });
                            },
                          },
                        },
                      };
                    }, delay);
                  });
              },
            },
          },
          cancel: {
            on: {
              click: () => {
                that.$modals.close();
              },
            },
          },
        },
      });
    },
    image(action) {
      const that = this;
      let self;
      const selfName = 'ServerAction';
      let res = '';
      let time;
      this.$modals.open({
        name: selfName,
        html: true,
        text: this.$t('modal.sure.text', {
          msg: this.$t(`text.${action.key}`),
          cost: this.$n(this.cost(`${action.key}`), 'currency', this.$i18n.locale),
        }),
        onOpen(inst) {
          self = inst;
        },
        footer: {
          confirm: {
            props: {
              title: this.$t('modal.sure.confirm'),
            },
            on: {
              click: () => {
                self.text = null;
                self.component = BaseLoader;
                self.closable = false;
                self.footer = false;
                time = Date.now();
                this.isProcessing = true;
                // console.log(this.id);
                this.createImage(this.id, action.action)
                  .then(data => {
                    this.imageId = data.id;
                    res = 'success';
                  })
                  .catch(() => {
                    res = 'fail';
                  })
                  .finally(() => {
                    const delay = Date.now() - time < 1000 ? 1000 : 0;
                    setTimeout(() => {
                      self.component = null;
                      // console.log(res);
                      self.closable = true;
                      self.text = this.$t(`modal.sure.${res}`);
                      self.footer = {
                        centered: true,
                        confirm: {
                          props: { title: this.$t('modal.sure.close') },
                          on: {
                            click: () => {
                              this.$modals.close();
                              // this.$store.dispatch('moduleStack/fetchPrivateImages');
                              if (this.typeAction === 'disk')
                                this.$router
                                  .push({
                                    name: 'viewStackImage',
                                  })
                                  .catch(err => {
                                    if (
                                      err.name !== 'NavigationDuplicated' &&
                                      !err.message.includes(
                                        'Avoided redundant navigation to current location'
                                      )
                                    ) {
                                      this.showErrorModal(err);
                                    }
                                  });
                            },
                          },
                        },
                      };
                    }, delay);
                  });
              },
            },
          },
          cancel: {
            on: {
              click: () => {
                that.$modals.close();
              },
            },
          },
        },
      });
    },
    deleteSnapshot(snap) {
      return this.$store.dispatch('moduleStack/deleteSnapshot', {
        snap: snap,
      });
    },
    deleteCurrentDisk(disk) {
      return this.$store.dispatch('moduleStack/deleteDisk', {
        disk: disk,
      });
    },
    setBootableDisk(disk, bootable) {
      return this.$store.dispatch('moduleStack/setBootableDisk', {
        disk: disk,
        bootable: bootable,
      });
    },
    bootableDisk() {
      const that = this;
      let self;
      const selfName = 'ServerAction';
      let res = '';
      let time;
      this.$modals.open({
        name: selfName,
        text: this.isBoot ? this.$t('text.abootable') : this.$t('text.bootable'),
        onOpen(inst) {
          self = inst;
        },
        footer: {
          confirm: {
            props: {
              title: this.$t('modal.sure.confirm'),
            },
            on: {
              click: () => {
                self.text = null;
                self.component = BaseLoader;
                self.closable = false;
                self.footer = false;
                time = Date.now();
                this.isProcessing = true;
                const bootable = this.tariff.bootable === 'true' ? 'False' : 'True';
                this.setBootableDisk(this.id, bootable).then(() => {
                  // console.log(data);
                });
                const delay = Date.now() - time < 1000 ? 1000 : 0;
                setTimeout(() => {
                  self.component = null;
                  self.closable = true;
                  self.text = this.$t('modal.sure.success');
                  self.footer = {
                    centered: true,
                    confirm: {
                      props: { title: this.$t('modal.sure.close') },
                      on: {
                        click: () => {
                          this.$modals.close();
                          this.$store.dispatch('moduleStack/fetchSnapshotsServer', this.projectId);
                          this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'volumes');
                          if (this.typeAction === 'disk')
                            this.$router.push({ name: 'containerDisk' }).catch(err => {
                              if (
                                err.name !== 'NavigationDuplicated' &&
                                !err.message.includes(
                                  'Avoided redundant navigation to current location'
                                )
                              ) {
                                this.showErrorModal(err);
                              }
                            });
                        },
                      },
                    },
                  };
                }, delay);
              },
            },
          },
          cancel: {
            on: {
              click: () => {
                that.$modals.close();
              },
            },
          },
        },
      });
    },
    async checkStatus(callback) {
      this.timerId = setTimeout(() => {
        this.timerCnt = 1;
        this.$store
          .dispatch('moduleStack/checkSnapshotStatus', { id: this.id, silent: true })
          .then(async data => {
            if (data !== 'ACTIVE') {
              await this.update(callback);
            } else {
              this.timerCnt = 0;
              this.isProcessing = false;
              clearTimeout(this.timerId);
            }
          });
      }, 1000 * this.timerCnt);
    },
    handleBillMgrTool(item) {
      if (item.tool.type === 'window') this.goToWindow(item);
      else if (item.tool.type === 'group') this.sendGroupTool(item);
      else throw new Error(`Handler for tool type ${item.tool.type} is not isset`);
    },
    goToWindow(item) {
      this.$store
        .dispatch('moduleStack/fetchBillMgrToolAction', {
          func: item.tool.func,
          elid: this.tariff.id,
        })
        .then(data => {
          if (data && data.ok && data.ok.type === 'url' && data.ok.v) {
            let payload = item.payload ? serialize(item.payload) : '';
            window.open(data.ok.v + payload);
          }
        });
    },
    sendServerAction(params) {
      return this.$store.dispatch('moduleStack/setServerAction', {
        id: this.tariff.id,
        params,
      });
    },
    createSnapshot(params) {
      return this.$store.dispatch('moduleStack/createSnapshot', {
        params,
      });
    },
    createImage(id, params) {
      const payload = {
        id: id,
        params: params,
      };
      return this.$store.dispatch('moduleStack/createImageFromDisk', payload);
    },
    async updateServerInfo(callback) {
      this.timerId = setTimeout(() => {
        this.$store
          .dispatch('moduleStack/updateList', { id: this.id, silent: true })
          .then(async data => {
            if (['REBOOT', 'HARD_REBOOT'].includes(data)) {
              this.res = data;
              await this.updateServerInfo(callback);
            } else {
              this.timerCnt = 0;
              this.isProcessing = false;
              clearTimeout(this.timerId);
              this.res = data;
              return 'success';
            }
          });
      }, 1000 * this.timerCnt);
    },
    async updateStatus() {
      this.timerId = setTimeout(() => {
        this.$store
          .dispatch('moduleStack/updateList', { id: this.id, silent: true })
          .then(async data => {
            if (['REBOOT', 'HARD_REBOOT'].includes(data)) {
              if (this.timerCnt < 5) this.timerCnt += 1;
              this.res = data;
              await this.updateStatus();
            } else {
              this.res = data;
              this.timerCnt = 0;
              this.isProcessing = false;
              clearTimeout(this.timerId);
            }
          });
      }, 1000 * this.timerCnt);
      return this.res;
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "delete": "Диск удалится вместе со всеми снимками, Вы уверены?",
    "quotaSpaceDisks": "Вы достигли квоты по объему сетевых дисков, обратитесь в поддержку для изменения квоты",
    "actions": {
      "change": "изменить",
      "stop": "остановить",
      "gotoserver": "перейти в панель",
      "history": "история",
      "start": "запустить",
      "process": "обновляем...",
      "descr": "Изменить описание",
      "restart": "перезагрузить",
      "createBackup": "создать бэкап",
      "recreateServer": "пересоздать сервер",
      "removeServer": "удалить сервер",
      "hard": "Аппаратная перезагрузка",
      "soft": "Программная перезагрузка",
      "resize": "Увеличить размер диска",
      "snapshot": "Создать снимок",
      "attach": "Подключить к инстансу",
      "rename": "Переименовать диск",
      "deattach": "Отключить от инстанса",
      "image": "Создать образ",
      "delete": "Удалить диск",
      "bootable": "Сделать диск загрузочным",
      "abootable": "Сделать диск обычным"
    },
    "alarm": "Этот диск сейчас подключен к инстансу. В некоторых случаях, создание снимка с подключенного диска может привести к испорченному снимку.",
    "text": {
      "snapshot": "Cоздать снимок диска.",
      "image": "Cоздать образ диска.",
      "bootable": "Cделать диск загрузочным.",
      "abootable": "Cделать диск обычным."
    },
    "prolong": {
      "confirm" : "Сменить",
      "order": "Отмена",
      "title": "Увеличить размер диска"
    },
    "noact": {
      "noactions": "Нет доступных действий.",
      "suspended": "Услуга остановлена администратором."
    },
    "sure": {
      "confirm": "Подтвердить",
      "delete": "Удалить"
    },
    "modal": {
      "sure": {
        "text": "Вы собираетесь <b>%{msg}</b>.<br /><br /> Ваш расход увеличится на <b>%{cost}</b> в час.",
        "textAlarm": "Вы собираетесь <b>%{msg}</b>.<br /><br /> Ваш расход увеличится на <b>%{cost}</b> в час. <br /><br /><u>Этот диск сейчас подключен к инстансу. В некоторых случаях, создание снимка с подключенного диска может привести к испорченному снимку.</u><br />",
        "confirm": "Подтвердить",
        "errorSnapshot": "У диска уже создан снапшот. Чтобы создать новый снапшот, удалите сначала старый.",
        "delete": "Диск <b>%{name}</b> успешно удалён, пожалуйста дождитесь обновления информации.",
        "close": "Закрыть",
        "success": "Запрос выполняется. Пожалуйста, дождитесь изменения статуса.",
        "fail": "Кажется, что-то пошло не так. Пожалуйста, попробуйте позже."
      }
    },
    "description": {
      "name": "Описание диска:",
      "text" : "Текстовое описание",
      "confirm": "Подтвердить"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';

$vcm-colors = {
  primary: $primary-color,
  success: $success-color,
  del: $color-red.light,
  warn: $color-light.medium,

}
$vcm-colors-hover = {
  primary: $color-blue.medium,
  success: darken($success-color, 20%),
  del: darken($color-red.light, 20%),

}

.stack-context-menu {

  &__group {
    & + & {
      margin-top: 2.5rem;

      +breakpoint(sm-and-up) {
        margin-top: (1.25rem * 3);
      }
    }
  }
  &__item {
    display: flex;
    color: var(--text);
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s ease;
    &-btn{
      margin-right: 5px;
    }

    &:first-letter {
      text-transform: uppercase;
    }

    &:focus {
      outline: none;
    }
    &:hover {
      color: $primary-color;
    }

    &[disabled] {
      pointer-events: none;
      cursor: default;
      opacity: 0.6;
    }

    & + & {
      margin-top: 1.25rem;
    }

    for $key, $value in $vcm-colors {
      &--{$key} {
        color: $value;
      }
    }
    for $key, $value in $vcm-colors-hover {
      &--{$key}:hover {
        color: $value;
      }
    }

  }

  &__noact {
    div + div {
      margin-top: 0.75rem;
    }
  }
}
</style>
<style lang="stylus">
@require '~@/assets/styles/vars/variables';

.stack-context-menu {
  .action-popup & {
    padding: 1.5rem 1rem;
    width: calc(100vw - 0.75rem);

    +breakpoint(sm-and-up) {
      padding: 1.5rem;
      width: 260px;
    }
  }
}
</style>
